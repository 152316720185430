import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { createProvider } from '@/vue-apollo'

const apolloInstance = createProvider()
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export { router, apolloInstance }
