export const resolvers = {
  Mutation: {
    setMe: (_, user, { cache }) => {
      const data = {
        Me: {
          __typename: 'Me',
          ...user
        }
      }
      cache.writeData({ data })
      return user
    }
  }
}
