import { REFRESH_TOKEN } from '@/graphql/mutations'
import { restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import { print } from 'graphql/language/printer'
import { DateTime } from 'luxon'

export const localStorageKeys = [
  'refresh_token',
  'token',
  'exp',
  'id',
  'name',
  'avatar',
  'roles'
]

export const AUTH_NAMESPACE = process.env.VUE_APP_AUTH_NAMESPACE

export const onLogin = async ({ apollo = null, data }) => {
  if (typeof localStorage !== 'undefined' && data) {
    localStorageKeys.forEach(k => {
      localStorage.setItem(`${AUTH_NAMESPACE}.${k}`, data[k])
    })
  }

  // if (apollo) await resetApolloCache(apollo)
}

export const onLogout = async ({ apollo = null }) => {
  if (typeof localStorage !== 'undefined') {
    localStorageKeys.forEach(k => {
      localStorage.removeItem(`${AUTH_NAMESPACE}.${k}`)
    })
  }

  // if (apollo) await resetApolloCache(apollo)
}

export const refreshToken = async refreshToken => {
  const resp = await fetch(`${process.env.VUE_APP_GRAPHQL_HTTP}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: print(REFRESH_TOKEN),
      variables: { refresh_token: refreshToken }
    })
  })

  const { data, errors } = await resp.json()

  if (errors) {
    return errors
  }

  return data
}

export const isAccessTokenValid = () => {
  const { exp, token } = getAuthData()
  return !!(exp && exp > DateTime.local().toSeconds() && token)
}

export const getAuthData = () => {
  const data = {}
  if (typeof localStorage !== 'undefined') {
    localStorageKeys.forEach(k => {
      data[k] = localStorage.getItem(`${AUTH_NAMESPACE}.${k}`)
    })
  } else {
    localStorageKeys.forEach(k => {
      data[k] = null
    })
  }

  return data
}

export const isLogged = () => {
  const { exp, token } = getAuthData()
  return (exp && exp > DateTime.local().toSeconds()) || !!token
}

export const resetApolloCache = async apollo => {
  const client = apollo.getClient()
  if (client.wsClient) restartWebsockets(client.wsClient)
  try {
    await client.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}
