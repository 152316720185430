<template>
  <div id="store-app">
    <WrapperLayout>
      <router-view />
    </WrapperLayout>
  </div>
</template>

<script>
import WrapperLayout from '@/layouts/WrapperLayout'

export default {
  name: 'App',
  components: { WrapperLayout }
}
</script>

<style lang="scss"></style>
