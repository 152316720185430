import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { typeDefs } from '@/graphql/typeDefs'
import { resolvers } from '@/graphql/resolvers'
import { onCacheInit } from '@/graphql/helpers'
import { AUTH_NAMESPACE, getAuthData, isAccessTokenValid, onLogin, onLogout, refreshToken } from '@/common/utils/auth'

const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:8080/v1/graphql'

const link = new TokenRefreshLink({
  accessTokenField: 'refreshToken',
  isTokenValidOrUndefined: () => {
    const { token } = getAuthData()
    return !!(isAccessTokenValid() || !token)
  },
  fetchAccessToken: () => refreshToken(getAuthData().refresh_token),
  handleFetch: data => onLogin({ data }),
  handleResponse: (operation, accessTokenField) => response => response,
  handleError: async err => {
    if (err) {
      await onLogout({ apollo: null })
    }
  }
})
  .concat(setContext((request, { headers }) => {
    const { token } = getAuthData()
    return token ? { headers: { authorization: `Bearer ${token}` } } : { headers }
  }))
  .concat(createHttpLink({ uri: httpEndpoint }))

// Config
const defaultOptions = {
  httpEndpoint,
  wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:8080/v1/graphql',
  tokenName: `${AUTH_NAMESPACE}.token`,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
  typeDefs,
  resolvers,
  defaultHttpLink: false,
  link,
  onCacheInit
}

export {
  defaultOptions
}
