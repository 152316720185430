import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import { router, apolloInstance } from './router'
import '../node_modules/@conceptho/fontawesome-pro/scss/fontawesome.scss'
import '../node_modules/@conceptho/fontawesome-pro/scss/light.scss'
import '../node_modules/@conceptho/fontawesome-pro/scss/brands.scss'
// import '../node_modules/@conceptho/fontawesome-pro/scss/solid.scss'
// import '../node_modules/@conceptho/fontawesome-pro/scss/duotone.scss'
import './assets/styles/main.scss'
import 'vue-loading-overlay/dist/vue-loading.css'

Vue.config.productionTip = false

new Vue({
  router,
  apolloProvider: apolloInstance,
  render: h => h(App)
}).$mount('#app')
