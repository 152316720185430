import gql from 'graphql-tag'

export const typeDefs = gql`
  type Me {
    id: Int!
    name: String!
    avatar: String
    refresh_token: String!
    token: String!
    exp: Int!
    roles: [String]!
  }
  type Mutation {
    setMe (
      id: Int!
      name: String!
      avatar: String
      refresh_token: String!
      token: String!
      exp: Int!
      roles: [String]!
    ): Me
  }
`
