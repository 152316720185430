import { getAuthData } from '@/common/utils/auth'

const onCacheInit = cache => {
  const user = getAuthData()
  if (user.id) {
    cache.writeData({
      data: {
        Me: {
          __typename: 'Me',
          ...user
        }
      }
    })
  }
}

export {
  onCacheInit
}
