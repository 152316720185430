const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
    meta: {
      requiresAuth: false,
      layout: 'Default'
    }
  },
  {
    path: '/checkout/:plan_id?',
    name: 'Checkout',
    component: () => import('@/views/Checkout'),
    meta: {
      requiresAuth: false,
      layout: 'Checkout'
    }
  },
  {
    path: '/clube',
    name: 'Club',
    component: () => import('@/views/Club'),
    meta: {
      requiresAuth: false,
      layout: 'Club'
    }
  }
]

export default routes
